@use './assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.fullPage {
  overflow-x: hidden;

  * {
    box-sizing: border-box;
  }
  *:focus-visible {
    outline: 0.2rem solid $aris-primary-blue !important;
  }
}

.container {
  padding: 0 18.4rem;
  margin: 0 auto;
  max-width: 190.2rem;
  width: 100%;
  box-sizing: border-box;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  .container {
    padding: 0 12.4rem;
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .container {
    padding: 0 10.4rem;
  }
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .container {
    padding: 0 9.2rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .container {
    padding: 0 6.4rem;
  }
}

@media screen and (max-width: 986px) {
  .container {
    padding: 0 5.8rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .container {
    padding: 0 4.2rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .container {
    padding: 0 2.4rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .container {
    padding: 0 2rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .container {
    padding: 0 0.8rem;
  }
}
