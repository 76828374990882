@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.faqContainer {
  padding: 7.2rem 24.4rem 9.8rem;
  margin: 0 auto;
  max-width: 190.2rem;
  width: 100%;
}

.faqsInner {
  padding-top: 0.6rem;
}

.faqHeader {
  color: $aris-primary-blue;
  text-transform: uppercase;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  font-size: 3.4rem;
  white-space: nowrap;
  line-height: 1.4;
  letter-spacing: 0.01rem;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .faqContainer {
    padding-left: 18.4rem;
    padding-right: 18.4rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .faqContainer {
    padding-left: 10.4rem;
    padding-right: 10.4rem;
  }
  .faqHeader {
    font-size: 3.2rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .faqContainer {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .faqHeader {
    font-size: 3rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .faqHeader {
    font-size: 2.4rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .faqContainer {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .faqHeader {
    font-size: 2rem;
    text-align: center;
    white-space: wrap;
  }
  .faqContainer {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}
