@use '../../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.ctaCallLink {
  display: block;
  color: $aris-primary-blue !important;
  text-decoration: underline;
  font-size: 2.4rem;
  font-family: 'DIN', 'Poppins', sans-serif;
  margin-top: 2.4rem;
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  transition: filter 120ms ease-in;

  &:hover {
    filter: brightness(0.8);
  }
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  /* Styles for standard laptops */
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .ctaCallLink {
    font-size: 2rem;
    margin-top: 2rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  /* Styles for tablets in portrait mode or large phones */
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .ctaCallLink {
    font-size: 1.6rem;
    margin-top: 1.6rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .ctaCallLink {
    margin-top: 1.4rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
