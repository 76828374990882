@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 4.2rem;
  gap: 1.8rem;
  font-size: 2.4rem;
}

.ctaCard {
  display: block;
  flex: 1;
  width: 100%;
  cursor: pointer;
  padding: 2rem 0;
  background-color: $aris-primary-blue;
  color: #fff;
  text-align: center;
  border-radius: $border-radius-md;
  line-height: 1.6;
  transition: filter 120ms ease;

  p {
    font-family: 'DIN', 'Poppins', sans-serif;
    line-height: 1.2;
    color: #fff;
  }

  &:hover {
    filter: brightness(0.9);
    color: #fff;
  }
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  /* Styles for standard laptops */
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .cardsContainer {
    font-size: 2rem;
  }

  .ctaCard {
    padding: 1.6rem 0;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .cardsContainer {
    font-size: 1.8rem;
  }
  .ctaCard {
    & > img {
      width: 6rem;
      height: 6rem;
    }
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .cardsContainer {
    font-size: 1.6rem;
    padding-top: 2.8rem;
    padding-bottom: 3.4rem;
  }
  .ctaCard {
    & > img {
      width: 5.2rem;
      height: 5.2rem;
    }
  }
  .getQuoteLabel {
    font-size: 1.6rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .cardsContainer {
    font-size: 1.4rem;
    display: block;
    padding-bottom: 2.4rem;
  }
  .ctaCard {
    margin-bottom: 0.8rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
