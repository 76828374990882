/* Default styles for large desktops (1920px and above) */
.socialMediaIconsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1.6rem;
  gap: 2.8rem;
  padding-bottom: 3rem;

  & > a {
    display: block;

    & > img {
      display: block;
    }
  }
}

.emptyContainer {
  padding-bottom: 1.4rem;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  .socialMediaIconsContainer {
    gap: 2.3rem;
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .socialMediaIconsContainer {
    gap: 2.2rem;
  }
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .socialMediaIconsContainer {
    gap: 1.8rem;
    & > a > img {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

@media screen and (max-width: 986px) {
  .socialMediaIconsContainer {
    padding-bottom: 2rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .socialMediaIconsContainer {
    gap: 2rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  /* Styles for tablets in portrait mode or large phones */
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .socialMediaIconsContainer {
    & > a > img {
      width: 3rem;
      height: 3rem;
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .socialMediaIconsContainer {
    & > a > img {
      width: 2.6rem;
      height: 2.6rem;
    }
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .socialMediaIconsContainer {
    gap: 1.6rem;
  }
}
