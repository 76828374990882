/* Default styles for large desktops (1920px and above) */
.header {
  position: static;
  height: 9.8rem;
  display: flex;
  align-items: center;
}

.arisLogo {
  object-fit: cover;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .header {
    height: 9rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .header {
    height: 8.6rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .header {
    height: 6.6rem;
  }
  .arisLogo {
    height: 6.2rem;
    width: 20rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .header {
    height: 6rem;
  }
  .arisLogo {
    height: 5.4rem;
    width: 19rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  /* Styles for standard smartphones */
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
