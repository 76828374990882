@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.modalContainer {
  max-height: 130vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 0.1rem solid $aris-primary-blue;
  padding-bottom: 1.6rem;

  * {
    box-sizing: border-box;
    font-family: 'DIN', 'Poppins', sans-serif;

    &:focus {
      outline: 0.2rem solid $aris-primary-blue;
    }
  }
}

.modalHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modalCloseButton {
  visibility: hidden;
  position: absolute;
  right: 2rem;
  font-size: 4.4rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.6rem;
  & > i {
    display: bock;
  }
  &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
  }
}

.modalHeader {
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 600;
  background-color: $aris-primary-blue;
  color: #fff;
  padding: 1.2rem;
  letter-spacing: 0.09rem;
}

.modalForm {
  display: flex;
  background-color: #fff;
  gap: 5rem;
  padding-top: 2.4rem;
  padding-bottom: 2.6rem;
  padding-left: 3.2rem;
  padding-right: 6rem;
  margin: 0 auto;
}

.modalInput {
  width: 100%;
  display: block;
  font-size: 1.8rem !important;
  background-color: #fff !important;
  height: 4.6rem;
  box-shadow: none;
  border: none;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: $aris-primary-blue !important;
  outline: 0.1rem solid $aris-primary-blue !important;

  &:focus {
    outline: 0.2rem solid $aris-primary-blue !important;
  }
  &:hover {
    outline: 0.2rem solid $aris-primary-blue !important;
  }

  &::placeholder {
    color: $aris-primary-blue !important;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $aris-primary-blue !important;
  }

  &:autofill,
  &:autofill:focus,
  &:autofill:hover,
  &:autofill:active {
    color: $aris-primary-blue !important;
    -webkit-text-fill-color: $aris-primary-blue !important;
  }
}

.modalColumnOne,
.modalColumnTwo {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.bestTimeSelectContainer {
  margin-bottom: 0.4rem;
}

.selectLabel {
  text-transform: capitalize;
  display: block;
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
  color: $aris-primary-blue;
}

.modalSelectWrapper {
  position: relative;
}

.modalSelectWrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 2rem; /* Position the custom caret */
  width: 2rem;
  height: 2rem;
  background: url('../../assets/icons/White_Drop_Down_Icon.png') no-repeat center center; /* Path to your custom caret icon */
  background-size: contain;
  pointer-events: none; /* Allow clicks to pass through the pseudo-element */
  transform: translateY(-50%);
}

.modalSelectWrapper.white::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 2rem; /* Position the custom caret */
  width: 2rem;
  height: 2rem;
  background: url('../../assets/icons/Blue_Drop_Down_Icon.png') no-repeat center center;
  background-size: contain;
  pointer-events: none; /* Allow clicks to pass through the pseudo-element */
  transform: translateY(-50%);
}

.modalSelectInput {
  font-family: 'DIN', 'Roboto', sans-serif;
  background-color: $aris-primary-blue;
  color: #fff;
  width: 100%;
  font-size: 1.8rem !important;
  height: 4.6rem;
  padding: 0.9rem 0.8rem;
  appearance: none; /* Remove the default caret */
  padding-right: 3rem; /* Add space for the custom caret */
  position: relative;
  border: none;
  box-shadow: none !important;
  outline: 0.1rem solid $aris-primary-blue;

  &:autofill,
  &:autofill:focus,
  &:autofill:hover,
  &:autofill:active {
    color: $aris-primary-blue !important;
    -webkit-text-fill-color: $aris-primary-blue !important;
  }
  &.white {
    background-color: #fff;
    color: $aris-primary-blue;

    &:focus,
    &:focus-visible {
      background-color: $aris-lightgrey !important;
      border: none !important;
      outline: 0.2rem solid $aris-primary-blue !important;
    }
  }

  &:hover {
    outline: 0.1rem $aris-primary-blue solid !important;
    box-shadow: none !important;
  }
}

.modalInput.inputError,
.modalSelectInput.inputError {
  border: none !important;
  outline: 0.2rem solid red !important;
  &:focus {
    outline: 0.2rem solid $aris-primary-blue !important;
  }
  &:hover {
    outline: 0.2rem solid $aris-primary-blue !important;
  }
}

.checkboxContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;

  .checkboxLabel {
    font-size: 1.8rem;
    color: $aris-primary-blue;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    width: 100%;
    .checkbox {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: #fff !important;
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: $aris-primary-blue;
      width: 2.4rem;
      height: 2.4rem;
      border: 0.12em solid $aris-primary-blue !important;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;

      &::before {
        content: '';
        width: 0.95em;
        height: 0.95em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 80ms transform ease-in-out;
        background-color: $aris-primary-blue;
      }

      &:checked::before {
        transform: scale(1);
      }

      &:focus,
      &:hover {
        outline: 0.2rem solid $aris-primary-blue !important;
        outline-offset: 0.2rem;
      }
    }

    .inputError {
      outline: 0.2rem solid red !important;
      outline-offset: 0.2rem;
    }
  }
}

.commentsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .comments {
    width: 100%;
    height: 100%;
    resize: none;
    background-color: #fff !important;
    border-color: $aris-primary-blue !important;
    padding: 1.2rem;
    font-size: 1.6rem !important;
    color: $aris-primary-blue !important;
    transition: none;
    border: none;
    outline: 0.1rem solid $aris-primary-blue;

    &:focus,
    &:hover {
      background-color: #fff !important;
      border: none !important;
      outline: 0.2rem solid $aris-primary-blue;
    }
    &:focus {
      background-color: $aris-lightgrey !important;
    }

    &:autofill,
    &:autofill:focus,
    &:autofill:hover,
    &:autofill:active {
      color: $aris-primary-blue !important;
      -webkit-text-fill-color: $aris-primary-blue !important;
    }
  }
}

.submitContainer {
  display: flex;
  padding-left: 3.2rem;
  padding-right: 6.4rem;
  padding-bottom: 2.4rem;
  gap: 5rem;
}

.emptyColumn,
.submitButtonContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.isCentered {
    justify-content: center;
  }
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  .modalContainer {
    width: 60%;
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .modalContainer {
    width: 65%;
  }
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .modalContainer {
    width: 82%;
  }
  .checkboxContainer {
    column-gap: 1.8rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .modalContainer {
    width: 88%;
  }
}

@media screen and (max-width: 986px) {
  .modalContainer {
    width: 96%;
  }
  .modalForm {
    gap: 3.2rem;
  }
  .modalCloseButton {
    visibility: visible;
  }
  .checkboxContainer {
    column-gap: 1.4rem;
    .checkboxLabel {
      font-size: 1.6rem !important;
      .checkbox {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .submitContainer {
    gap: 3.2rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .modalContainer {
    width: 100%;
  }

  .modalForm {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .checkboxContainer {
    grid-template-columns: 1fr;
    .checkboxLabel {
      font-size: 1.8rem !important;
      .checkbox {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .submitContainer {
    gap: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .modalContainer {
    width: 100%;
  }
  .modalHeader {
    font-size: 2rem;
    padding: 1rem;
  }

  .modalForm {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    gap: 2.2rem;
  }
  .modalInput,
  .modalSelectInput,
  .modalSelectWrapper {
    font-size: 1.6rem !important;
    height: 4.2rem;
  }
  .selectLabel {
    font-size: 1.6rem !important;
  }
  .checkboxContainer {
    grid-template-columns: 1fr;
    .checkboxLabel {
      font-size: 1.6rem !important;
      .checkbox {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .modalContainer {
    top: 0;
    left: 0;
    transform: none;
    height: 100%;
  }

  .modalHeader {
    font-size: 1.8rem;
  }
  .modalForm {
    flex-direction: column;
    flex: 1;
    max-height: 64vh;
    overflow-y: auto;
  }
  .commentsContainer {
    .comments {
      height: 18rem;
    }
  }
  .submitContainer {
    padding-top: 2.4rem;
  }
  .emptyColumn {
    display: none;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
}

@media screen and (max-height: 770px) {
  .modalContainer {
    max-height: 100%;
  }
}
