@use '../../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.disclaimerContainer {
  padding-left: 3.2rem;
  padding-right: 6.4rem;

  .checkboxSmall {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: $aris-primary-blue !important;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: $aris-primary-blue;
    width: 1.4rem;
    height: 1.4rem;
    border: 0.12em solid transparent !important;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-top: 0.4rem;

    &::before {
      content: '';
      width: 0.75em;
      height: 0.75em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 80ms transform ease-in-out;
      background-color: #fff;
    }

    &:checked::before {
      transform: scale(1);
    }
    &:focus,
    &:hover {
      outline: 0.1rem solid $aris-primary-blue !important;
      outline-offset: 0.2rem;
      background-color: $aris-primary-blue !important;
      border-color: transparent !important;
    }

    &.inputError {
      outline: 0.1rem solid red !important;
      outline-offset: 0.2rem;

      &:focus {
        outline: 0.1rem solid $aris-primary-blue !important;
      }
      &:hover {
        outline: 0.1rem solid $aris-primary-blue !important;
      }
    }
  }
}

.disclaimerContent {
  display: inline-flex;
  align-items: flex-start;
}

.disclaimerText {
  font-size: 1.2rem;
  margin-left: 0.6rem;
  color: $aris-primary-blue;
}

.privacyStatementLink {
  text-decoration: underline;
  color: $aris-primary-blue !important;
  &:hover {
    color: $aris-primary-blue;
  }
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  /* Styles for standard laptops */
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  /* Styles for tablets in landscape mode */
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  /* Styles for tablets in portrait mode or large phones */
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .disclaimerText {
    font-size: 1.1rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .disclaimerContainer {
    padding-right: 4.4rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .disclaimerContainer {
    padding-right: 3.2rem;
  }
}
