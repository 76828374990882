.footer {
  text-align: center;
  background: midnightblue;
  padding: 72px 50px 20px;
  box-sizing: border-box;
  font-family: 'Open Sans';
  font-size: 20px;
  color: #ffffff;

  .footerText {
    font-size: 14px;
    margin-bottom: 20px;
    position: relative;
    vertical-align: center;

    .copyright {
      text-align: center;
      margin-bottom: 4px;
    }

    .smsDisclaimer {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
    }
  }

  .socialNetworksWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;

    .socialNetworks {
      img {
        margin: 0px 13px;
      }
    }
    .light {
      img {
        filter: invert(1);
      }
    }
  }
}
.black {
  color: #000;
}
.white {
  color: #fff;
}
@media only screen and (max-width: (768px - 1px)) {
  .footer {
    padding: 15px;

    .socialNetworksWrapper {
      margin-bottom: 20px;

      .socialNetworks {
        margin-top: 15px;
      }
    }
  }
  .smsDisclaimer {
    // TODO:
    position: relative; /* Reset the position to relative for smaller screens */
    top: 0;
    right: 0; /* Reset the right position */
    margin-top: 10px; /* Add margin to create space below the .copyright element */
  }
}
