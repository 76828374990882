.wrapper {
  display: flex;
  justify-content: center;

  .content {
    width: 1070px;
  }

  .header {
    height: 160px;
    padding: 0px 70px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    .companyImageWrapper {
      display: flex;
      align-items: flex-end;

      .companyImage {
        max-width: 300px;
        max-height: 160px;
        float: bottom;
      }
    }

    .callMeWrapper {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin-bottom: 25px;

      .callMe {
        margin-top: 37px;
        color: #666666;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 16px;
        font-family: 'Arial';

        img {
          margin-right: 9px;
        }
      }

      button {
        width: 110px;
        height: 27px;
        background-color: #792c44;
        font-weight: 600;
        margin-left: 120px;
        border: none;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .cards {
    .card {
      margin: 30px 0px;
      padding-bottom: 50px;
      background: white;
      text-align: center;
      padding-top: 50px;

      .cardContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .infoTitle {
        margin-bottom: 37px;
        font-size: 32px;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
      }

      .infoText {
        margin: 0px 97px 44px 91px;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        word-break: break-word;
      }

      .formTitle {
        font-family: Arial;
        font-size: 32px;
        line-height: 41px;
        font-weight: 400;
      }

      .formSubtitle {
        font-family: Arial;
        font-size: 32px;
        font-weight: 500;
      }

      .formDescription {
        font-family: Arial;
        line-height: 26px;
        font-size: 18px;

        .uppercase {
          font-family: 'Arial';
          font-weight: 700;
          line-height: 26px;
          text-transform: uppercase;
        }
      }

      .formInputs {
        margin-top: 14px;
        width: 625px;

        input {
          max-width: 622px;
          height: 74px;
          border: 2px solid #898989;
          margin-top: 27px;
        }

        .nameContainer {
          display: flex;
          gap: 4px;
        }
      }

      // .preferredContactMethod {
      //   font-family: 'Arial';
      //   font-size: 18px;
      //   margin-top: 38px;
      //   margin-bottom: 58px;
      //   display: flex;
      //   justify-content: center;

      //   span:first-child {
      //     margin-right: 23px;
      //   }

      //   .preferredContactMethodRadio {
      //     display: flex;
      //     align-items: center;

      //     label:first-child {
      //       margin-right: 44px;
      //     }

      //     input {
      //       transform: scale(1.8);
      //       cursor: pointer;
      //       margin-right: 9px;
      //     }
      //   }
      // }

      button {
        width: 327px;
        height: 66px;
        background-color: #9d1114;
        font-size: 26px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
      }

      // .checkNewsletter {
      //   font-family: 'Arial';
      //   font-size: 18px;
      //   margin-top: 34px;
      //   margin-bottom: 8px;

      //   label {
      //     display: flex;
      //     flex-direction: row;
      //     align-items: center;
      //     justify-content: center;
      //   }

      //   label span:first-child {
      //     display: flex;
      //     flex-direction: row;
      //     align-items: center;
      //     justify-content: center;
      //     margin-right: 15px;
      //   }

      //   img {
      //     margin-left: -23px;
      //   }

      //   input {
      //     width: 28px;
      //     height: 28px;
      //     border: 1px solid #959595;
      //   }

      //   input:checked {
      //     background: rgba(0, 0, 0, 0);
      //     border: #00e5af;
      //   }
      // }
    }
  }

  .downloadInformation {
    height: 375px;

    .downloadTitle {
      margin-top: 27px;
      text-align: center;
      font-family: Arial;
      font-size: 22px;
      line-height: 44px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .hr {
      border-bottom: 1px midnightblue solid;
    }
  }

  .downloadInfoWrapper {
    display: flex;
    justify-content: space-around;

    .downloadInfoImg {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img:first-child {
        width: 110px;
      }

      .text {
        margin-bottom: 6px;
        margin-top: 12px;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        text-align: center;
      }
    }
  }
}

.contactInfo {
  padding: 40px 70px 3px 70px;

  .infoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .info {
      .agentName {
        word-break: break-word;
        margin-top: 35px;
        font-family: Arial;
        font-size: 56px;
        line-height: 65px;
        font-weight: 400;
      }

      .address {
        padding-top: 24px;
        font-family: 'Arial';
        font-size: 18px;
        text-align: left;
        word-break: break-word;

        .companyName {
          color: #070707;
          font-weight: bold;
          font-size: 25px;
          margin-bottom: 25px;
          line-height: 26px;
        }
      }
    }
  }

  .profileImageWrapper {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    text-align: center;

    .profileImage {
      height: 280px;
      object-fit: contain;
    }

    .profileImageAgentName {
      word-break: break-word;
      margin-top: 15px;
      font-family: Arial;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: #000000;
    }
  }

  hr {
    border: 1px solid #c2c2c2;
  }

  .infoBlocks {
    margin-top: 10px;
    display: flex;
    font-family: 'Arial';
    font-size: 18px;
    justify-content: flex-start;

    .infoBlock {
      display: flex;
      height: 40px;
      align-items: center;
      margin-right: 40px;

      img {
        transform: scale(0.6);
      }

      div {
        margin-right: 10px;

        a {
          color: black;
        }
      }
    }
    .socials {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      a {
        display: flex;

        &:hover {
          img {
            opacity: 0.7;
            transform: scale(0.95);
          }
        }

        img {
          transition: 0.1s;
          width: 60px;
          height: auto;
        }
      }

      .socialIcon {
        width: 35px;
        margin: 0 10px;
      }
    }
  }
}

.aboutMeWrapper {
  background: midnightblue;
  display: flex;
  justify-content: center;

  .aboutMe {
    width: 800px;
    background: midnightblue;
    //font-family: 'OpenSans';
    color: #ffffff;
    margin-top: 42px;
    margin-bottom: 49px;

    .text {
      font-size: 18px;
      line-height: 26px;
      word-wrap: break-word;
    }

    .title {
      font-size: 22px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 18px;
    }
  }
}

.strip {
  width: 100%;
  background: midnightblue;
  height: 45px;
}

.footerStrip {
  width: 100%;
  background: lightslategray;
  height: 15px;
}

.grey {
  background: whitesmoke;
}

.notification {
  p,
  li {
    font-size: 15px;
  }

  max-width: 900px;
}

.blackText {
  color: #000;
  font-weight: 600;
}

.isDanger {
  border-color: var(--border-danger) !important;
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}

@media only screen and (max-width: (950px - 1px)) {
  .wrapper {
    .contactInfo {
      .infoBlocks {
        margin-top: 0px;

        .infoBlock {
          margin-right: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: (800px - 1px)) {
  .aboutMeWrapper {
    .aboutMe {
      width: 768px;
    }
  }
  .wrapper {
    .contactInfo {
      padding: 40px 0px 3px 0px;

      .infoWrapper {
        padding: 0px 70px;
      }

      hr {
        display: none;
      }

      .infoBlocks {
        width: 100%;
        flex-direction: column;
        font-size: 40px;
        margin-top: 50px;

        .infoBlock:first-child {
          border-top: 1px solid #464646;
        }

        .infoBlock:last-child {
          img {
            margin-right: 17px;
            margin-left: 5px;
          }
        }

        .infoBlock {
          padding-left: 25vw;
          height: 108px;
          border-bottom: 1px solid #464646;

          img {
            margin-right: 12px;
            transform: scale(1);
          }
        }
      }
    }

    .content {
      .cards {
        .card {
          .infoText {
            text-align: center;
            font-size: 28px;
            line-height: 36px;
            margin: 0px 56px;
          }

          .infoTitle {
            font-size: 36px;
          }
        }
      }
    }
  }
  .aboutMeWrapper {
    .aboutMe {
      margin-top: 114px;
      margin-bottom: 99px;
      text-align: center;
      padding: 0px 55px;

      .text {
        font-size: 28px;
      }

      .title {
        font-size: 40px;
      }
    }
  }
}

@media only screen and (max-width: (426px - 1px)) {
  .aboutMeWrapper {
    .aboutMe {
      width: 375px;
    }
  }
  .wrapper {
    .header {
      padding: 0px;
      flex-direction: column;

      .companyImageWrapper {
        justify-content: center;

        .companyImage {
          margin: 10px 0;
          max-height: 70px;
        }
      }

      .callMeWrapper {
        align-items: center;

        .callMe {
          justify-content: center;
          margin: 0;
        }

        > button {
          margin: 0;
        }
      }
    }

    .contactInfo {
      .infoWrapper {
        padding: 0 20px;

        .info {
          .agentName {
            font-size: 20px;
            line-height: 20px;
          }

          .address {
            padding-top: 10px;
            font-size: 15px;
            line-height: 17px;

            .companyName {
              font-size: 17px;
              margin-bottom: 10px;
              line-height: 18px;
            }
          }
        }

        .profileImageWrapper {
          .profileImage {
            height: 150px;
          }
        }
      }

      .infoBlocks {
        .infoBlock {
          font-size: 25px;

          :first-child {
            width: 25px;
          }
        }
      }
    }

    .content {
      .cards {
        .card {
          .infoTitle {
            font-size: 25px;
            line-height: 29px;
          }

          .infoText {
            font-size: 18px;
            line-height: 20px;
          }

          .formTitle {
            font-size: 28px;
            line-height: 29px;
          }

          .formSubtitle {
            font-size: 23px;
            line-height: 29px;
            margin-top: 10px;
          }

          .formDescription {
            font-size: 18px;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
    }

    .downloadInfoWrapper {
      .downloadInfoImg {
        margin-top: 0;
        padding-bottom: 20px;

        .text {
          margin: 0;
        }
      }
    }
  }

  .aboutMeWrapper {
    .aboutMe {
      .text {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}
.medicareCenterWebpageLink {
  button {
    padding: 0 10px;
    height: 35px;
    background-color: #792c44;
    font-weight: 600;
    margin-left: 20px;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media only screen and (max-width: (326px - 1px)) {
  .infoBlock {
    width: 320px;
  }
  .aboutMeWrapper {
    .aboutMe {
      width: 320px;
    }
  }
  .content {
    .cards {
      .card {
        width: 320px;
        button {
          width: 315px;
        }
      }
    }
  }
}

.disclaimer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 80px;
  width: 622px;
  position: relative;

  .customCheckbox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
  }

  .checkbox {
    display: none;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #cacaca;
    border-radius: 4px;
    background-color: #fff;
  }

  /* Styling for the custom checkbox when it's checked */
  .checkbox:checked + .checkmark {
    background-image: url('/static/icons/webpage/custom-check.png');
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
  }

  .isCheckDanger {
    border: 2px solid var(--border-danger);
    background-color: transparent;
  }

  .content {
    text-align: left;
    margin-left: 36px;
    color: #4d4d4d;
    width: 100%;
  }
}

@media screen and (max-width: 675px) {
  .wrapper {
    overflow-x: hidden;
  }
  .nameContainer {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0px;
  }

  .formInputs {
    input {
      max-width: 90%;
      width: 80%;
    }
  }

  .cardContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
  .disclaimer {
    max-width: 80%;

    .label {
      margin-left: 10px;
    }
  }
}
