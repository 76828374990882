.ForbiddenWebpage,
.FourOhFourBack {
  background-color: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .ForbiddenWebpageText,
  .FourOhFourBackText {
    width: 400px;
    color: #a9a9a9;
    text-align: center;
    font-size: 24px;
  }
}
.FourOhFourBack {
  display: flex;
  flex-direction: column;

  .FourOhFourBackLink {
    font-size: 20px;
    color: var(--primary-color);
  }
}
