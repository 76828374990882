@use '../../../assets/styles/aris-webpage.scss';

/* Default styles for large desktops (1920px and above) */
.arisSelectContainer {
  font-family: 'DIN', 'Poppins', sans-serif;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .arisSelectControl,
  .arisValueContainer {
    min-height: 4.4rem !important;
  }
  .arisSelectDropdownIndicator {
    height: 4rem !important;
    width: 4rem !important;
  }
  .arisSelectPlaceholder,
  .arisSelectInput,
  .arisSelectOption,
  .arisSelectSingleValue,
  .arisMulti,
  .arisMultiValueLabel,
  .arisNoOptionsMessage {
    font-size: 1.8rem !important;
  }
}

@media screen and (max-width: 986px) {
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .arisSelectControl,
  .arisValueContainer {
    min-height: 4.2rem !important;
  }
  .arisSelectDropdownIndicator {
    height: 3.8rem !important;
    width: 3.8rem !important;
  }
  .arisSelectPlaceholder,
  .arisSelectInput,
  .arisSelectOption,
  .arisSelectSingleValue,
  .arisMulti,
  .arisMultiValueLabel,
  .arisNoOptionsMessage {
    font-size: 1.6rem !important;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .arisSelectControl,
  .arisValueContainer {
    min-height: 4rem !important;
  }
  .arisSelectDropdownIndicator {
    height: 3.6rem !important;
    width: 3.6rem !important;
  }

  .arisSelectPlaceholder,
  .arisSelectInput,
  .arisSelectOption,
  .arisSelectSingleValue,
  .arisMulti,
  .arisMultiValueLabel,
  .arisNoOptionsMessage {
    font-size: 1.4rem !important;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
