@use '../../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.faqItem {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  border-bottom: 0.4rem solid $aris-primary-blue;
  cursor: pointer;
}

.faqItemInner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faqTitle {
  text-align: left;
  font-size: 2.2rem;
  color: $aris-primary-blue;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
}

.faqIcon {
  margin-right: 2.4rem;
  transition: transform 220ms ease;
  &.opened {
    transform: rotate(-180deg);
  }
}

.faqBody {
  font-size: 1.8rem;
  color: $aris-primary-blue;
  padding-right: 8%;
  max-height: 0;
  transform: scaleY(0);
  overflow: hidden;
  transition: transform 220ms ease, padding-top 220ms ease, padding-bottom 220ms ease;
  & > p {
    font-family: 'DIN', 'Poppins', sans-serif;
  }

  &.opened {
    height: 100%;
    transform: scaleY(1);
    max-height: 40rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  /* Styles for standard laptops */
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  /* Styles for tablets in landscape mode */
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .faqTitle {
    font-size: 2rem;
  }
  .faqBody {
    font-size: 1.6rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .faqTitle {
    font-size: 1.8rem;
  }
  .faqBody {
    font-size: 1.4rem;
  }
  .faqIcon {
    margin-right: 0.4rem;
    & > img {
      width: 2.6rem;
      height: 2.6rem;
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  /* Styles for standard smartphones */
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .faqTitle {
    font-size: 1.6rem;
  }
  .faqBody {
    font-size: 1.4rem;
  }
}
