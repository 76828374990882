@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.detailsContainer {
  padding: 9.2rem 0;
  display: flex;
  justify-content: space-between;
}

.detailsHeading {
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  font-size: 2.4rem;
  text-transform: uppercase;
  color: $aris-primary-blue;
  letter-spacing: 0.09rem;
  margin-bottom: 0.8rem;
}

.detailsContent {
  color: $aris-primary-blue;
  font-family: 'DIN', 'Poppins', sans-serif;
  font-size: 2rem;
}

.columnOne {
  flex: 1;
  text-transform: capitalize;
}

.officeHours {
  display: flex;
  width: 75%;
  margin-bottom: 0.4rem;

  .detailsContent {
    flex: 1;
    white-space: nowrap;
    min-width: 15.4rem;
  }
}

.columnTwo {
  flex: 1;
  border-left: 0.4rem solid $aris-primary-blue;
  border-right: 0.4rem solid $aris-primary-blue;
}

.connectWrapper {
  padding-left: 10%;
  padding-right: 4%;
}

.connectItem {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 0.6rem;
}

.addresses {
  align-items: flex-start;
}

.columnThree {
  flex: 1;
  text-transform: capitalize;
}

.extraWrapper {
  padding-left: 22%;
  padding-right: 8%;
}

.languages {
  margin-bottom: 2.4rem;
}

.licenseList {
  columns: 2;
  column-gap: 0.4rem;
}

@media screen and (max-width: 1680px) {
  .columnTwo {
    flex: 1.2;
  }
  .extraWrapper {
    padding-left: 10%;
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .columnTwo {
    flex: 1.4;
  }
  .connectWrapper {
    padding-left: 6%;
  }
  .officeHours {
    width: 100%;
  }
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .columnTwo {
    flex: 1.4;
  }
  .detailsHeading {
    font-size: 2.2rem;
  }
  .detailsContent {
    font-size: 1.8rem;
  }
  .connectWrapper {
    padding-left: 4%;
  }
  .extraWrapper {
    padding-left: 6%;
    padding-right: 1%;
  }
  .connectItem {
    & > img {
      width: 3.8rem;
      height: 3.8rem;
    }
  }
  .officeHours {
    .detailsContent {
      min-width: 0;
      &.timeRange {
        margin-left: -3.8rem;
      }
    }
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .detailsContainer {
    padding: 7.4rem 0;
  }
  .columnTwo {
    flex: 1.5;
  }
  .connectWrapper {
    padding-left: 4%;
  }
}

@media screen and (max-width: 986px) {
  .detailsContainer {
    display: block;
    padding: 6rem 0;
  }
  .columnOne {
    margin-bottom: 2rem;
  }
  .columnTwo {
    flex: 1;
    border-top: 0.4rem solid $aris-primary-blue;
    border-bottom: 0.4rem solid $aris-primary-blue;
    border-left: none;
    border-right: none;
    .detailsHeading {
      padding-top: 2rem;
    }
  }
  .columnThree {
    .detailsHeading {
      padding-top: 2rem;
    }
  }
  .connectWrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .extraWrapper {
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }
  .officeHours {
    .detailsContent {
      min-width: 0;
      &.timeRange {
        margin-left: -16rem;
      }
    }
  }
  .languages {
    flex: 1;
    margin-bottom: 0;
    & > p {
      width: 50%;
    }
  }
  .licenses {
    flex: 1;
    margin-left: -16rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .detailsContainer {
    padding: 5.4rem 0;
  }
  .licenses {
    margin-left: 0;
  }
  .languages {
    & > p {
      width: 80%;
    }
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .detailsContainer {
    padding: 4.2rem 0;
  }
  .detailsHeading {
    font-size: 2rem;
  }
  .detailsContent {
    font-size: 1.6rem;
  }
  .columnTwo,
  .columnThree {
    .detailsHeading {
      padding-top: 1.6rem;
    }
  }
  .connectItem {
    & > img {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .connectItem {
    margin-bottom: 0.1rem;
    gap: 1.2rem;
    & > img {
      width: 3rem;
      height: 3rem;
    }
  }
  .detailsHeading {
    font-size: 1.8rem;
  }
  .detailsContent {
    font-size: 1.4rem;
  }
  .officeHours {
    .detailsContent {
      min-width: 0;
      &.timeRange {
        margin-left: 0;
      }
    }
  }
  .extraWrapper {
    justify-content: space-between;
    gap: 1.1rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .officeHours {
    .detailsContent {
      min-width: 0;
      &.timeRange {
        margin-left: 0;
      }
    }
  }
}
