@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.newsContainer {
  width: 100%;
  max-width: 190.2rem;
  margin: 0 auto;
  background-image: url('../../assets/icons/Latest_News_and_Resources_Background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 3rem 9.2rem 8.2rem;
}

.newsHeading {
  color: $aris-primary-blue;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  font-size: 4.8rem;
  text-align: center;
  margin-bottom: 3.8rem;
  letter-spacing: 0.01rem;
}

.blogCardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  opacity: 0.2;
  filter: blur(0.5rem);
  transition: opacity 100ms ease-in-out, filter 100ms ease-in-out;

  &.visible {
    opacity: 1;
    filter: blur(0);
  }
}

.readMoreIconLeft {
  position: absolute;
  top: 39%;
  left: -3.8%;
}

.readMoreIconRight {
  position: absolute;
  top: 39%;
  right: -3.8%;
}

.emptyBlogCard {
  flex: 0 1 calc(21.8%);
  background-color: transparent;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  .readMoreIconLeft {
    left: -4.8%;
  }

  .readMoreIconRight {
    right: -4.8%;
  }

  .emptyBlogCard {
    flex: 0 1 calc(23%);
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .newsContainer {
    padding-left: 5.4rem;
    padding-right: 5.4rem;
  }

  .readMoreIconLeft {
    left: -3.9%;
  }

  .readMoreIconRight {
    right: -3.9%;
  }
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .readMoreIconLeft {
    left: -4.4%;
  }

  .readMoreIconRight {
    right: -4.4%;
  }

  .emptyBlogCard {
    flex: 0 1 calc(32%);
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .newsHeading {
    font-size: 4.4rem;
  }
  .readMoreIconLeft {
    left: -5.2%;
  }
  .readMoreIconRight {
    right: -5.2%;
  }
}

@media screen and (max-width: 986px) {
  .newsContainer {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
  }

  .readMoreIconLeft {
    left: -5.4%;
  }
  .readMoreIconRight {
    right: -5.4%;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .newsContainer {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .newsHeading {
    font-size: 4rem;
  }
  .readMoreIconLeft {
    left: -7%;
  }
  .readMoreIconRight {
    right: -7%;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .newsContainer {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 5.4rem;
  }
  .newsHeading {
    font-size: 3.4rem;
  }
  .readMoreIconLeft {
    left: -10%;
    & > img {
      width: 3rem;
      height: 3rem;
    }
  }
  .readMoreIconRight {
    right: -10%;
    & > img {
      width: 3rem;
      height: 3rem;
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .newsContainer {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .newsHeading {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .newsContainer {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }
  .readMoreIconLeft {
    left: -10%;
    & > img {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
  .readMoreIconRight {
    right: -10%;
    & > img {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}
