@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.requestContainer {
  background-color: $aris-secondary-yellow;
  border-radius: $border-radius-lg;
  text-align: center;
  padding: 3.2rem 8.8rem;
}

.requestHeading {
  font-size: 4.8rem;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  color: $aris-primary-blue;
  line-height: 1.2;
  letter-spacing: 0.01rem;
}

.requestSubHeading {
  font-size: 2.8rem;
  font-weight: 600;
  font-family: 'DIN', 'Poppins', sans-serif;
  color: $aris-primary-blue;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .requestContainer {
    padding: 3.2rem 6.4rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .requestHeading {
    font-size: 4.4rem;
  }
  .requestSubHeading {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 986px) {
  .requestContainer {
    padding: 3.6rem 4.6rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .requestContainer {
    padding: 3.6rem 4.4rem;
  }
  .requestHeading {
    font-size: 4rem;
  }
  .requestSubHeading {
    font-size: 2rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .requestContainer {
    padding: 3.6rem 2.4rem;
  }
  .requestHeading {
    font-size: 3.4rem;
    margin-bottom: 0.6rem;
  }
  .requestSubHeading {
    font-size: 1.8rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .requestContainer {
    padding: 2.4rem 1.6rem;
  }
  .requestHeading {
    font-size: 3rem;
  }
  .requestSubHeading {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
