@font-face {
  font-family: 'DIN';
  src: url('../fonts/D-DIN.otf') format('opentype');
}

@font-face {
  font-family: 'DIN-Bold';
  src: url('../fonts/D-DIN-Bold.otf') format('opentype');
}

$aris-primary-blue: #3a6f8f;
$aris-secondary-yellow: #d5db90;
$border-radius-lg: 2.8rem;
$border-radius-md: 1.6rem;
$border-radius-sm: 1.2rem;
$aris-lightgrey: #ededed;
