@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.aboutMeContainer {
  margin: 0 auto;
  text-align: center;
  margin-top: 6.4rem;
  border-radius: $border-radius-lg;
  border-color: $aris-primary-blue;
  border-width: 0.2rem;
  border-style: solid;
  padding: 0 7.2rem 2.8rem;
}

.aboutMeHeading {
  padding-top: 2.8rem;
  font-size: 4.8rem;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  color: $aris-primary-blue;
  line-height: 1.2;
  letter-spacing: 0.01rem;
}

.agentBio {
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: left;
  font-size: 2.4rem;
  color: $aris-primary-blue;
  border-bottom: 0.2rem solid $aris-primary-blue;
  & > p {
    font-family: 'DIN', 'Poppins', sans-serif;
  }
}

.productsContainer {
  margin: 0 auto;
  text-align: center;
}

.productsHeading {
  padding-top: 2.8rem;
  padding-bottom: 2rem;
  font-size: 3.2rem;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  color: $aris-primary-blue;
  line-height: 1.2;
  letter-spacing: 0.01rem;
}

.productsList {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  gap: 0.8rem;
}

.productItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  justify-content: space-between;
}

.productName {
  font-size: 2.2rem;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  color: $aris-primary-blue;
  word-wrap: break-word;
  min-height: 5.4rem;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .aboutMeContainer {
    padding: 0 6.4rem 2.8rem;
  }
  .agentBio {
    font-size: 2.2rem;
  }
  .productItem {
    & > img {
      width: 8rem;
      height: 8rem;
    }
  }
  .productName {
    font-size: 2rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .aboutMeHeading {
    font-size: 4.4rem;
  }
  .productsHeading {
    font-size: 3rem;
  }
  .agentBio {
    font-size: 2rem;
  }
  .productItem {
    & > img {
      width: 7rem;
      height: 7rem;
    }
  }
  .productName {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 986px) {
  .aboutMeContainer {
    padding: 0 4.6rem 3rem;
  }

  .productsList {
    row-gap: 2.4rem;
    grid-template-columns: repeat(6, 1fr); // 3 items in the first row
    grid-template-rows: auto auto; // Two rows

    // First row (3 items)
    li:nth-child(1) {
      grid-column: 1 / span 2;
    }
    li:nth-child(2) {
      grid-column: 3 / span 2;
    }
    li:nth-child(3) {
      grid-column: 5 / span 2;
    }
    // Second row (2 items centered)
    li:nth-child(4) {
      grid-column: 1 / span 2;
    }
    li:nth-child(5) {
      grid-column: 5 / span 2;
    }
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .aboutMeContainer {
    margin-top: 4rem;
    padding: 0 4.4rem 2.8rem;
  }
  .aboutMeHeading {
    font-size: 4rem;
  }
  .agentBio {
    font-size: 1.8rem;
  }
  .productsHeading {
    font-size: 2.8rem;
  }
  .productName {
    font-size: 2rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .aboutMeContainer {
    padding: 0 2.4rem 2.8rem;
  }
  .aboutMeHeading {
    padding-top: 2.4rem;
    font-size: 3.4rem;
  }
  .agentBio {
    font-size: 1.6rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .productsHeading {
    font-size: 2.4rem;
  }
  .productName {
    font-size: 1.6rem;
    max-width: 8.6rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .aboutMeContainer {
    padding: 0 1.6rem 2.8rem;
  }
  .aboutMeHeading {
    font-size: 3rem;
  }
  .agentBio {
    font-size: 1.4rem;
  }
  .productsHeading {
    font-size: 2.2rem;
  }
  .productName {
    font-size: 1.4rem;
  }
  .productItem {
    & > img {
      width: 6rem;
      height: 6rem;
    }
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .productItem {
    & > img {
      width: 4rem;
      height: 4rem;
    }
  }
}
