@use '../../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.submitButton {
  background-color: $aris-primary-blue;
  color: #fff;
  border-radius: 3.2rem;
  font-size: 2.4em;
  padding: 1.8rem 2.8rem;
  text-transform: uppercase;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  position: relative;

  transition: filter 120ms ease-in;

  &.bottom {
    background-color: $aris-secondary-yellow;
    color: $aris-primary-blue;
    margin-top: 3.6rem;
    &:focus {
      outline: 0.2rem solid $aris-secondary-yellow !important;
      outline-offset: 0.2rem !important;
    }
  }

  &:hover,
  &:focus-visible,
  &:focus {
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.75;
  }
}

.spinner {
  visibility: hidden;
  position: absolute;
  display: block;
  z-index: 1000;

  &.isSubmitting {
    visibility: visible;
  }
}

.buttonLabel {
  visibility: visible;

  &.isSubmitting {
    visibility: hidden;
  }
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .submitButton {
    font-size: 2em;
    padding: 1.4rem 2.4rem;
    &.bottom {
      margin-top: 2.4rem;
    }
  }
}

@media screen and (max-width: 986px) {
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .submitButton {
    font-size: 1.6em;
    padding: 1.2rem 2rem;
    &.bottom {
      margin-top: 2rem;
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .submitButton {
    &.bottom {
      margin-top: 1.6rem;
    }
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
