@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.newsContainer {
  width: 100%;
  max-width: 190.2rem;
  margin: 0 auto;
  background-image: url('../../assets/icons/Latest_News_and_Resources_Background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 3rem 9.2rem;
}

.newsHeading {
  color: $aris-primary-blue;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  font-size: 4.8rem;
  text-align: center;
  letter-spacing: 0.01rem;
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .newsContainer {
    padding-left: 5.4rem;
    padding-right: 5.4rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .newsHeading {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 986px) {
  .newsContainer {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .newsContainer {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .newsContainer {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 5.4rem;
  }

  .newsHeading {
    font-size: 3.4rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .newsContainer {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .newsHeading {
    font-size: 3rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .newsContainer {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }
}
