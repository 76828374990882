@use '../../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.blogCard {
  flex: 0 1 calc(21.8%);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 1.6rem;
  border-radius: $border-radius-sm;
}

.blogCardImage {
  width: 100%;
  height: 20.6rem;
  object-fit: cover;
  background-color: #6e8778;
  border-radius: $border-radius-sm;
}

.blogCardInner {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  border-bottom-left-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
  height: 26rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blogCardHeader {
  line-height: 1.5;
  min-height: 6.2rem;
  max-height: 6.2rem;
  color: $aris-primary-blue;
  font-size: 2rem;
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  margin-top: 1.8rem;
  margin-bottom: 0.8rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  line-clamp: 2;
}

.blogCardContent {
  font-size: 1.8rem;
  font-family: 'DIN', 'Poppins', sans-serif;
  color: $aris-primary-blue;
  max-height: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  line-clamp: 3;
}

.readMoreLink {
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: text-decoration 120ms ease-in;
  text-decoration-color: $aris-primary-blue !important;
  text-underline-offset: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
}

.readMoreText {
  font-family: 'DIN', 'Poppins', sans-serif;
  color: $aris-primary-blue;
  font-size: 2rem;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  .blogCard {
    flex: 0 1 calc(23%);
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .blogCardInner {
    padding: 0 2rem;
  }
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .blogCard {
    flex: 0 1 calc(32%);
  }
  .blogCardInner {
    padding: 0 1.6rem;
  }
  .blogCardImage {
    height: 18.6rem;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  /* Styles for tablets in landscape mode */
}

@media screen and (max-width: 986px) {
  .blogCardInner {
    height: 22rem;
  }
  .blogCardImage {
    height: 16.6rem;
  }
  .blogCardHeader {
    min-height: 5.4rem;
    max-height: 5.4rem;
    font-size: 1.8rem;
  }
  .blogCardContent {
    font-size: 1.6rem;
    max-height: 8rem;
  }
  .readMoreLink,
  .readMoreText {
    font-size: 1.8rem;
    & > img {
      width: 3.4rem;
      height: 3.4rem;
    }
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .blogCard {
    flex: 0 1 calc(47.8%);
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .blogCard {
    flex: 1;
  }
  .blogCardInner {
    height: 18.6rem;
  }
  .blogCardHeader {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
  .blogCardImage {
    height: 16rem;
  }
  .blogCardContent {
    font-size: 1.4rem;
  }
  .readMoreLink,
  .readMoreText {
    font-size: 1.6rem;
    gap: 0.6rem;
    & > img {
      width: 3rem;
      height: 3rem;
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  /* Styles for standard smartphones */
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
