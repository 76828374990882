@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.footerContainer {
  background-color: $aris-primary-blue;
  padding: 16rem 0;
  width: 100%;
  max-width: 190.2rem;
  margin: 0 auto;
}

.footerHeader {
  font-family: 'DIN-Bold', 'Poppins', sans-serif;
  color: #fff;
  font-size: 6.4rem;
  line-height: 1.8;
  letter-spacing: 0.01rem;
  text-align: center;
}

.footerSubHeader {
  color: #fff;
  font-size: 2.6rem;
  margin-bottom: 3.6rem;
  text-align: center;
  font-family: 600;
  font-family: 'DIN', 'Poppins', sans-serif;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  /* Styles for standard laptops */
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .footerContainer {
    padding: 14rem 0;
  }
  .footerHeader {
    font-size: 6rem;
  }
  .footerSubHeader {
    font-size: 2.4rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .footerHeader {
    font-size: 5.6rem;
  }
  .footerSubHeader {
    font-size: 2.2rem;
    margin-bottom: 2.4rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .footerContainer {
    padding: 10rem 0;
  }
  .footerHeader {
    line-height: 1.3;
    font-size: 4.6rem;
  }
  .footerSubHeader {
    font-size: 2rem;
    padding: 0 4.8rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .footerHeader {
    font-size: 4rem;
    margin-bottom: 0.4rem;
  }
  .footerSubHeader {
    font-size: 1.6rem;
    padding: 0 5rem;
    margin-bottom: 0.4rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
