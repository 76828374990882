@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.bannerContainer {
  width: 100%;
  margin: 0 auto;
}

.banner {
  line-height: 0;
  position: relative;
  height: 29.5rem;
  max-width: 190.2rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
}

.profilePhotoContainer {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.profilePhoto {
  object-fit: cover;
  border-radius: 100%;
  display: block;
  border: 0.4rem solid white;
  width: 23.4rem;
  height: 23.4rem;
}

.agentInfoContainer {
  padding-top: 12rem;

  .agentInfoContainerCenter {
    text-align: center;
  }
  .agentInfoContainerCompanyInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10rem;

    .agentInfoContainerCompanyInfoLeft,
    .agentInfoContainerCompanyInfoRight {
      width: 35%;
    }

    .agentInfoContainerCompanyInfoHeader {
      font-size: 3rem;
      color: $aris-primary-blue;
      text-align: center;
    }

    .agentInfoContainerCompanyInfoBody {
      font-size: 1.5rem;
      color: $aris-primary-blue;
    }

    @media (max-width: 1024px) {
      /* Mobile */
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 1rem;

      .agentInfoContainerCompanyInfoLeft,
      .agentInfoContainerCompanyInfoRight {
        width: 90%;
        /* Full width on small screens */
        margin-bottom: 2rem;
      }

      .agentInfoContainerCompanyInfoHeader {
        font-size: 2rem;
      }

      .agentInfoContainerCompanyInfoBody {
        font-size: 1.2rem;
      }
    }
  }
}

.agentName {
  color: $aris-primary-blue;
  font-size: 5.2rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 800;
  line-height: 1.4;
}

.agentTitle {
  font-size: 2.4rem;
  color: $aris-primary-blue;
  font-family: 'DIN', 'Poppins', sans-serif;
  padding: 0 1rem;
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  /* Styles for slightly smaller desktops */
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  /* Styles for standard laptops */
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .agentName {
    font-size: 4.8rem;
  }

  .agentTitle {
    font-size: 2rem;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .agentInfoContainer {
    padding-top: 10rem;
  }
  .profilePhoto {
    width: 20.4rem;
    height: 20.4rem;
  }
  .agentName {
    font-size: 4.4rem;
  }
  .agentTitle {
    font-size: 1.8rem;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .banner {
    height: 22.5rem;
  }
  .agentInfoContainer {
    padding-top: 8.6rem;
  }
  .profilePhoto {
    width: 18.4rem;
    height: 18.4rem;
  }
  .agentName {
    font-size: 4.2rem;
  }
  .agentTitle {
    font-size: 1.6rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .banner {
    height: 20.5rem;
  }
  .agentName {
    font-size: 3.6rem;
  }
  .agentTitle {
    font-size: 1.4rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .agentInfoContainer {
    padding-top: 7.2rem;
  }
  .agentName {
    font-size: 3.2rem;
  }
  .agentTitle {
    text-align: center;
    font-size: 1.2rem;
  }
  .profilePhoto {
    width: 15rem;
    height: 15rem;
  }
}
