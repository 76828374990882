@use '../../assets/styles/aris-webpage.scss' as *;

/* Default styles for large desktops (1920px and above) */
.formContainer {
  display: flex;
  gap: 8.4rem;
  padding: 2.8rem 0;
}

.formColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.formInput {
  width: 100%;
  display: block;
  background-color: #fff !important;
  box-shadow: none;
  border: none;
  height: 4.6rem;
  font-size: 2rem !important;
  padding: 0rem 1.2rem;
  color: $aris-primary-blue !important;
  transition: none;

  &:hover {
    outline: 0.2rem solid $aris-primary-blue !important;
  }

  &:focus {
    outline: 0.2rem solid $aris-primary-blue !important;
  }

  &::placeholder {
    color: $aris-primary-blue !important;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $aris-primary-blue !important;
  }

  &:autofill,
  &:autofill:focus,
  &:autofill:hover,
  &:autofill:active {
    color: $aris-primary-blue !important;
    -webkit-text-fill-color: $aris-primary-blue !important;
  }
}

.inputError {
  outline: 0.2rem solid red !important;
  &:focus {
    outline: 0.2rem solid red !important;
  }
  &:hover {
    outline: 0.2rem solid red !important;
  }
}

.selectWrapper {
  width: 100%;
  position: relative;
  select.formInput {
    appearance: none; /* Remove the default caret */
    padding-right: 3rem; /* Add space for the custom caret */
    position: relative;
  }
}

.selectWrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 2rem; /* Position the custom caret */
  width: 2rem;
  height: 2rem;
  background: url('../../assets/icons/Blue_Drop_Down_Icon.png') no-repeat center center; /* Path to your custom caret icon */
  background-size: contain;
  pointer-events: none; /* Allow clicks to pass through the pseudo-element */
  transform: translateY(-50%);
}

.ctaButtonContainer {
  margin: 0 auto;
  text-align: center;
  padding-top: 1.2rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

.bottomFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.6rem 34%;
  gap: 1.6rem;
  box-sizing: border-box;

  .selectWrapper,
  .formInput {
    &:hover,
    &:focus,
    &:focus-visible {
      outline: 0.2rem solid #fff !important;
      outline-offset: 0.2rem;
    }
  }
}

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  .bottomFormContainer {
    padding: 1.6rem 30%;
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  /* Styles for high-end laptops or smaller desktops */
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .formContainer {
    gap: 6.4rem;
  }

  .bottomFormContainer {
    padding: 1.6rem 26%;
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .formInput {
    font-size: 1.8rem !important;
    height: 4.4rem;
  }
}

@media screen and (max-width: 986px) {
  .formContainer {
    gap: 4.6rem;
  }
  .bottomFormContainer {
    padding: 1.6rem 20%;
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .bottomFormContainer {
    padding: 1.6rem 14%;
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .formContainer {
    display: block;
  }
  .formColumn {
    display: block;
  }
  .formInput {
    font-size: 1.6rem !important;
    height: 4.2rem;
    margin-bottom: 0.8rem;
  }
  .ctaButtonContainer {
    padding-top: 0;
  }
  .bottomFormContainer {
    gap: 0.8rem;
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .formInput {
    font-size: 1.4rem !important;
    height: 4rem;
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  /* Styles for smaller smartphones */
}
