.webpage {
  h4,
  h5 {
    font-weight: 500;
    margin: 0;
    b {
      font-weight: 600;
    }
    &.light {
      color: var(--white);
    }
  }

  .userWrapper {
    max-width: 1400px;
    margin: 0 auto;
    .userInfo {
      background: linear-gradient(90deg, #fff calc(55% - 50px), transparent calc(45% - 30px));
      word-break: break-word;
    }
    .headingSection {
      background: linear-gradient(
        90deg,
        var(--secondary-btn-background-color) calc(55% - 50px),
        transparent calc(45% - 30px)
      );
      padding-top: 25px;
      padding-bottom: 130px;
      .container {
        flex-direction: column;
        position: relative;
        .form {
          position: absolute;
          top: 65px;
          border-radius: 14px;
          padding: 35px;
          box-shadow: var(--webpage-form-shadow);
          width: 65%;
          .headerWithIcon {
            display: flex;
            align-items: center;
            max-width: 550px;
            margin-bottom: 20px;
            i {
              font-size: 50px;
              margin-right: 10px;
            }
          }
          form {
            .flexedFields {
              display: flex;
              justify-content: space-between;
              max-width: 580px;
              margin: 0 auto;
              .formWrapper,
              .fullWidth {
                width: 48.5%;
                .field {
                  margin-bottom: 10px;
                  width: 100%;
                  input {
                    transition: 0.1s;
                    height: 55px;
                    font-size: 16px;
                    border-radius: 14px;
                    background-color: var(--white) !important;
                    border-color: var(--white) !important;
                    &:focus,
                    &:hover {
                      border-color: transparent !important;
                      box-shadow: var(--box-shadow);
                    }
                    &.isDanger {
                      border-color: var(--border-danger) !important;
                    }
                    &::placeholder {
                      text-align: center;
                    }
                  }
                }
              }

              &.bottom {
                .field.withCheckbox {
                  width: 48.5%;
                  display: flex;
                  align-items: flex-end;
                  flex-direction: column;
                  label::before {
                    transition: 0.1s;
                    border-radius: 6px;
                    background-color: var(--white);
                    border: none;
                    outline: none;
                  }
                  label {
                    outline: none;
                    &:focus,
                    &:hover {
                      &::before {
                        box-shadow: var(--box-shadow);
                      }
                    }
                  }
                  .flex {
                    display: flex;
                  }
                }
                .submitWrapper {
                  width: 48.5%;
                  button {
                    color: var(--white);
                    width: 100%;
                    height: 70px;
                    padding: 15px;
                    display: flex;
                    justify-content: center;
                    border: none;
                    border-radius: 14px;
                    font-size: 16px;
                    font-weight: 600;
                    transition: 0.1s;
                    &:hover {
                      opacity: 0.7;
                    }
                    &.dark {
                      color: var(--dark-gray-20);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .container {
      p {
        font-size: 18px;
        color: var(--dark-gray);
        &.isDanger {
          color: var(--border-danger);
          font-size: 12px;
        }
      }
      .white {
        color: var(--white);
        word-break: break-word;
      }
      .black {
        color: var(--black);
        word-break: break-word;
      }
      display: flex;
      justify-content: space-between;
      .mobileContent {
        display: none;
      }
      .leftContent {
        width: 55%;
        .userDescription {
          padding-top: 50px;
          .companyImage {
            max-width: 300px;
            height: auto;
            object-fit: contain;
            margin-bottom: 35px;
          }
          h4 {
            max-width: 520px;
            margin-bottom: 60px;
          }
          .withSeparator {
            position: relative;
            &:before {
              position: absolute;
              bottom: 25px;
              left: 50%;
              transform: translateX(-50%);
              content: '';
              width: 200px;
              height: 2px;
              background-color: var(--dark-gray);
            }
          }
          .aboutMe {
            margin-bottom: 35px;
            max-width: 530px;
          }
        }
      }
      .rightContent {
        width: 45%;
        padding: 0 25px;
        .profileWrapper {
          .socials {
            padding-top: 80px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            a {
              display: flex;

              &:hover {
                img {
                  opacity: 0.7;
                  transform: scale(0.95);
                }
              }
              img {
                transition: 0.1s;
                width: 60px;
                height: auto;
              }
            }
            .socialIcon {
              width: 60px;
              margin: 0 10px;
            }
          }
          .mainInfo {
            .main {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .profileImage {
                height: auto;
                max-width: 250px;
              }
              .description {
                text-align: center;
                text-transform: capitalize;
                h4 {
                  width: 360px;
                }
              }
              margin-bottom: 20px;
            }
            .addresses {
              padding: 20px 15px;
              margin: 0 auto;
              text-align: center;
              .infoSection {
                margin-bottom: 10px;
                a {
                  transition: 0.1s;
                  p {
                    transition: 0.1s;
                  }
                  &:hover {
                    opacity: 0.7;
                    p {
                      opacity: 0.7;
                    }
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .divider {
    background-color: var(--secondary-btn-background-color);
    max-width: 1400px;
    margin: 0 auto;
    height: 360px;
  }
  .informationDownload {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    padding-top: 25px;
    &:before {
      content: '';
      height: 20px;
      width: 100%;
      position: absolute;
      top: -30px;
      background-color: var(--webpage-divider-color);
    }
    .filesInfo {
      display: flex;
      justify-content: space-between;
      padding-bottom: 130px;
      .headerWithIcon {
        display: flex;
        align-items: center;
        max-width: 550px;
        margin-bottom: 20px;
        i {
          font-size: 50px;
          transform: rotate(90deg);
          margin-right: 10px;
        }
      }
      .fileList {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        position: absolute;
        right: 0;
        bottom: 4px;
        .fileWrapper {
          overflow: hidden;
          margin-right: 15px;
          border: 2px solid var(--dark-gray);
          border-radius: 14px;
          background-color: var(--white);
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 20px 5px;
          .previewImage {
            width: 100%;
            max-height: 175px;
            overflow-y: hidden;
            display: flex;
            justify-content: center;
            img {
              object-fit: contain;
              width: 100% !important;
              opacity: 1 !important;
              height: auto !important;
            }
          }
          .info {
            margin-bottom: 15px;
            padding: 0 15px;
            p {
              word-break: break-word;
              font-size: 15px;
              text-align: center;
              font-weight: 600;
              color: var(--dark-gray);
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
          i {
            font-size: 90px;
            transition: 0.1s;
            margin-bottom: 15px;
            color: var(--dark-gray);
          }
          .download {
            position: absolute;
            bottom: 5px;
            font-size: 50px;
            transform: rotate(90deg);
            color: var(--secondary-btn-background-color);
            opacity: 0.7;
          }
          &:hover {
            i {
              color: var(--dark-gray);
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: (1900px + 1px)) {
  .webpage {
    .userWrapper {
      .userInfo {
        background: linear-gradient(90deg, #fff 50%, transparent 50%);
      }
      .headingSection {
        background: linear-gradient(90deg, var(--secondary-btn-background-color) 50%, transparent 50%);
      }
    }
  }
}

@media only screen and (max-width: 1408px) {
  .webpage {
    .main {
      .description {
        h4 {
          font-size: 2.9rem;
          line-height: 35px;
          width: 280px !important;
        }
      }
    }
    .userWrapper {
      .userInfo {
        background: linear-gradient(90deg, #fff calc(55% - 15px), transparent calc(45% - 15px));
      }
      .headingSection {
        background: linear-gradient(
          90deg,
          var(--secondary-btn-background-color) calc(55% - 15px),
          transparent calc(45% - 15px)
        );
      }
    }
  }
}

@media only screen and (max-width: (1216px - 1px)) {
  .webpage {
    .main {
      .description {
        h4 {
          width: 160px !important;
        }
      }
    }
    .container {
      padding: 0 15px;
    }
    .userWrapper {
      .userInfo {
        background: linear-gradient(90deg, #fff 55%, transparent 45%);
      }
      .headingSection {
        background: linear-gradient(90deg, var(--secondary-btn-background-color) 55%, transparent 45%);
      }
    }
  }
}

@media only screen and (max-width: (991px - 1px)) {
  .webpage {
    .main {
      .description {
        h4 {
          width: auto !important;
        }
      }
    }
    .userWrapper {
      .container {
        flex-direction: column;
        align-items: center;
        .leftContent,
        .rightContent {
          width: 100%;
        }
        .leftContent {
          .userDescription {
            h4 {
              max-width: 100%;
            }
            p {
              max-width: 100%;
            }
          }
        }
        .rightContent {
          .profileWrapper {
            .socials {
              padding-top: 20px;
            }
          }
        }
      }
      .headingSection {
        .container {
          .form {
            width: 98%;
          }
        }
      }

      .userInfo {
        background: transparent;
      }
      .headingSection {
        background: linear-gradient(var(--secondary-btn-background-color) 55%, transparent 45%);
      }
    }
    .informationDownload {
      .filesInfo {
        padding-bottom: 25px;
        margin-bottom: 0;
        flex-direction: column;
        .fileList {
          position: static;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width: (768px - 1px)) {
  .webpage {
    h4,
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    .informationDownload {
      .filesInfo {
        .headerWithIcon {
          margin-bottom: 0;
        }
        .fileList {
          flex-direction: column;
          .fileWrapper {
            margin: 0;
            margin-top: 15px;
          }
        }
      }
    }
    .userWrapper {
      .container {
        display: flex;
        flex-direction: column-reverse;

        p {
          font-size: 14px;
          color: white;
        }
        .mobileContent {
          display: block;
          order: 3;
          padding: 0;
          .mobileCompanyImage {
            margin: 20px 0;
          }
        }
        .leftContent {
          order: 1;
          padding: 0;
          .userDescription {
            padding-top: 30px;

            .companyImage {
              display: none;
            }

            h4 {
              margin: 10px 0;
              font-size: 18px;
              color: white;
            }
            .withSeparator {
              display: none;
            }
            .aboutMe {
              max-width: 100%;
              margin-bottom: 10px;
              color: white;
            }
          }
        }
        .rightContent {
          order: 2;
          padding: 0;
          .profileWrapper {
            .socials {
              padding: 0;
              margin: 10px 0;

              .stayConnected {
                font-size: 13px;
                margin-right: 5px;
              }

              .socialIcon {
                width: 35px;
                margin: 0 3px;
              }
            }
            .mainInfo {
              margin: 15px 0;

              .main {
                margin: 0;

                .profileImage {
                  margin: 0;
                }
                .description {
                  margin: 10px 0;
                  h4 {
                    font-size: 30px;
                  }
                  .title {
                    font-size: 15px;
                  }
                }
                flex-direction: column;
                img {
                  margin-bottom: 15px;
                }
              }
              .addresses {
                padding: 10px;

                .infoSection p {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .headingSection {
        .container {
          .headerWithIcon {
            h5 {
              font-size: 13px;
            }
          }
          .form {
            top: 45px;
            form {
              width: 99%;
              .flexedFields {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .formWrapper,
                .fullWidth {
                  width: 100%;
                }
                &.bottom {
                  .field.withCheckbox,
                  .submitWrapper {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .divider {
      height: 440px;
    }
  }
}
